import React from "react";
import axios from "axios";
import { Input } from "semantic-ui-react";
import DateSelector from "./DateSelector";
import "./Invoice.css";

class SearchInput extends React.Component {
  state = {
    entry: 2200,
    amount: "",
    endDatePicker: "",
    startDatePicker: "",
    startDate: "",
    endDate: "",
    customers: [],
    startAmount: "",
  };

  componentWillMount = async () => {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const startdateMonth = startDate.getMonth() + 1;
    const startCurr_Date =
      startDate.getFullYear() +
      "-" +
      startdateMonth +
      "-" +
      startDate.getDate();

    let endDate = new Date();
    endDate.setDate(endDate.getDate());
    const enddateMonth = endDate.getMonth() + 1;
    const endCurr_Date =
      endDate.getFullYear() + "-" + enddateMonth + "-" + endDate.getDate();

    this.setState({
      endDate: endCurr_Date,
      endDatePicker: endDate,
      startDate: startCurr_Date,
      startDatePicker: startDate,
    });

    if (this.props.adminCust) {
      const response = await axios.get(
        `https://www.getgokart.com/gokart/inv3a_7/inv_cust.php`
      );
      let responseArray = [];
      responseArray = response.data.results;
      responseArray.sort();
      const customers = responseArray.map((customer) => {
        return (
          <option value={customer.app_user_id}>{customer.customer}</option>
        );
      });

      this.setState({ customers: customers });
    } else {
      this.setState({ entry: this.props.app_user_id });
      this.props.onSearchSubmit(
        this.props.app_user_id,
        startCurr_Date,
        "",
        endCurr_Date
      );
    }
  };

  onFormSubmit = (event) => {
    event.preventDefault();

    this.props.onSearchSubmit(
      this.state.entry,
      this.state.startDate,
      this.state.amount,
      this.state.endDate
    );
    this.setState({ startAmount: "", amount: "" });
  };

  onCsvDownload = (event) => {
    this.props.onCsvDownload(
      this.state.entry,
      this.state.startDate,
      this.state.amount,
      this.state.endDate
    );
  };

  fetchDate = (startDate) => {
    this.setState({ startDate: startDate });
  };

  fetchEndDate = (endDate) => {
    this.setState({ endDate: endDate });
  };

  handleChange(event) {
  }

  amountChange = (entry) => {
    this.setState({ amount: entry.target.value });
    this.setState({ startAmount: entry.target.value });
    //  this.props.amount(entry.target.value)
  };

  logout = (e) => {
    if (e) {
      this.props.logout(true);
    }
  };

  render() {
    return (
      <div className="ui container" >
        <form onSubmit={this.onFormSubmit}>
          <div>
            {this.props.adminCust ? (
              <h3 style={{  marginLeft: "20px" }}>
                Select Customer
              </h3>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >

              </div>
            )}
          </div>
          {this.props.adminCust ? (
            <select
              className="dropdownPicker"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "20px",
              }}
              value={this.state.value}
              onChange={(event) => this.setState({ entry: event.target.value })}
            >
              {this.state.customers}
            </select>
          ) : (
            <label> </label>
          )}
          <div
            style={{
              display: "inline-block",
              width: "auto",
              marginTop: "2px",
              marginLeft: "20px",
              marginBottom: "2px",
            }}
          >
            <p
              style={{
                display: "inline-block",
                width: "auto",
                marginBottom: "5px",
              }}
            >
              Select Dates to Search From:
            </p>
            <DateSelector
              fetchDate={this.fetchDate}
              startDate={this.state.startDate}
              startDatePicker={this.state.startDatePicker}
            />
            <p
              style={{
                display: "inline-block",
                width: "auto",
                marginBottom: "5px",
              }}
            >
              To:
            </p>
            <DateSelector
              fetchDate={this.fetchEndDate}
              startDate={this.state.endDate}
              startDatePicker={this.state.endDatePicker}
            />
            <p
              style={{
                display: "inline-block",
                width: "auto",
                marginBottom: "5px",
              }}
            >
              Enter Invoice Amount (optional){" "}
              <Input
                focus
                placeholder="Search Amount"
                onChange={this.amountChange}
                value={this.state.startAmount}
              />
            </p>
          </div>
          <button className="ui primary button" style={{ margin: "5px" }}>
            Search
          </button>
          <div  style = {{textAlign :'right'}}>
          <button className="ui primary button" onClick={this.onCsvDownload}>
            CSV Download
          </button>
          </div>
        </form>
      </div>
    );
  }
}

export default SearchInput;
