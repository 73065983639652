import React from "react";

import nochanges from './images/nochanges.png';
import PriceCheckList from "./PriceCheckList";
import Upload from "./Upload";
import {
  Container,
  Image,
  Icon,
  Button, Header, Segment
} from "semantic-ui-react";
import axios from "axios";


class PriceCheck extends React.Component {

    state = {
      priceCheck: []
    };

  componentDidMount() {
    console.log("in Price Check and app_user_id is: "+this.props.app_user_id);
    this.priceCheck(this.props.app_user_id);
  }


  priceCheck = async (app_user_id) => {
    const paramsid = { app_user: this.props.app_user_id};
    console.log(paramsid)
    const response = await axios.post(
      `https://phpstack-612014-1983801.cloudwaysapps.com/pricecheck`,
      paramsid,
      {
        headers: this.headers,
      }
    );





    if (response.data.message === "success") {
      this.setState({ priceCheck: response.data.results, error: "" });
      console.log("Price Check Response received"+response.data.message+ "array length is "+this.state.priceCheck.length)
      console.log("price Check array is:"+JSON.stringify(response.data.results))
    } else {
      this.setState({
        priceCheck: response.data.results,
        error: " No Price Changes",
      });
      console.log("Price Check Response FAILED")
    }
  };



  render() {
    return (
<div>

{this.state.priceCheck.length >0 ?
<PriceCheckList priceCheck={this.state.priceCheck}/>
:

<Container align = "center" style={{ marginTop: "20px" }}>
<Icon bordered inverted color='teal' name = "checkmark" size = "big" /><h2>Check Your Prices </h2>
<p>We will tell you when you are paying too much</p>
<p>Upload an invoice to start</p>
<Upload />

</Container>
}
</div>

    )}


}

export default PriceCheck;
