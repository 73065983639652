import React, { createRef } from "react";
import {
  Image,
  Icon,
  Grid,
  Menu,
  Sticky,
  Container,
  Header,
  Button,
} from "semantic-ui-react";
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import InputBox from "./InputBox";
import HomeView from "./HomeView";
import "./Invoice.css";

import mixpanel from "mixpanel-browser";

mixpanel.init("2d772417592a72a1c53239ba9ed8c7cd");

class LoginView extends React.Component {
  state = {
    placeholderText: "Enter Phone Number",
    pinPlaceholderText: "Enter Pin Number",
    pin: "",
    phone: "",
    app_user_id: 0,
    admin: false,
    error: "",
    sidebarVisible: false,
    priceChangeView: "true",
    invoiceView: "false",
    priceCheckView: false,
    activeItem: "Price Changes",
    activePricesButtonColour: "blue",
    activeInvoiceButtonColour: "black",
  };

  componentDidMount = () => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log("user logged in is", JSON.parse(loggedInUser));
      this.setState({ app_user_id: loggedInUser });
      mixpanel.identify(loggedInUser);
    }
  };

  onSubmit = (entry, pinOrPhoneNumber) => {
    this.setState({ [pinOrPhoneNumber]: entry, error: "" });
  };

  login = async () => {
    if (this.state.phone === "admin" && this.state.pin === "bakz") {
      this.setState({ admin: true, app_user_id: true });
    } else {
      let phone = this.state.phone;
      let pin = this.state.pin;
      const response = await axios.get(
        `https://www.getgokart.com/gokart/inv3a_7/login.php?phone=${phone}&pin=${pin}`
      );
      if (response.data.results !== "false") {
        this.setState({ app_user_id: response.data.results });
        localStorage.setItem("user", response.data.results);
        mixpanel.identify(response.data.results);
      } else {
        this.setState({
          pin: "",
          phone: "",
          app_user_id: 0,
          error: "Pin or Phone Number Not Recognised",
        });
      }
    }
  };

  onLogout = (e) => {
    if (e) {
      this.setState({ app_user_id: 0 });
      localStorage.clear();
    }
  };

  menuClicked = () => {
    this.setState((prevState) => ({
      sidebarVisible: !prevState.sidebarVisible,
    }));
  };

  buttonClickPrices = (e) => {
    this.setState({
      priceChangeView: "true",
      invoiceView: "false",
      priceCheckView: false,
      activeItem: "Price Changes",
      activePricesButtonColour: "blue",
      activeInvoiceButtonColour: "black",
    });
    this.menuClicked();
  };

  buttonClickInvoices = (e) => {
    this.setState({
      priceChangeView: "false",
      invoiceView: "true",
      priceCheckView: false,
      activeItem: "Download Invoices",
      activePricesButtonColour: "black",
      activeInvoiceButtonColour: "blue",
    });
    this.menuClicked();
  };


buttonClickPriceCheck = (e) => {
  mixpanel.track('Upload invoices',{
    distinct_id: this.props.app_user_id});
  this.setState({
    priceChangeView: "false",
    invoiceView: "false",
    priceCheckView: true,
    activeItem: "Check Prices",
    activePricesButtonColour: "black",
    activeInvoiceButtonColour: "blue",
  });
  this.menuClicked();
};



  contextRef = createRef();

  render() {
    if (this.state.app_user_id !== 0) {
      return (
        <div>
          <div ref={this.contextRef}>
            <Sticky context={this.contextRef}>
              <Container
                textAlign="center"
                style={{ backgroundColor: "#ffffff" }}
              >
                <Image
                  src="https://www.getgokart.com/gokart/wp-content/uploads/2017/10/shakened logo.png"
                  size="medium"
                  spaced={true}
                  style={{ marginTop: "10px" }}
                />
                <Header as="h4">Keeping an eye on prices for you</Header>

                <Menu pointing>
                  <Menu.Item
                    active={this.state.activeItem === "Price Changes"}
                    color={this.state.activePricesButtonColour}
                    onClick={this.buttonClickPrices}
                  >
                    <Icon name="chart line" />
                    Price Changes
                  </Menu.Item>
                  <Menu.Item
                    active={this.state.activeItem === "Check Prices"}
                    color={this.state.activeInvoiceButtonColour}
                    onClick={this.buttonClickPriceCheck}
                  >
                    <Icon name="eye" />
                    Check Prices
                  </Menu.Item>
                  <Menu.Item
                    active={this.state.activeItem === "Download Invoices"}
                    color={this.state.activeInvoiceButtonColour}
                    onClick={this.buttonClickInvoices}
                  >
                    <Icon name="file" />
                    Download Invoices
                  </Menu.Item>


                  <Menu.Menu position="right">
                    <BrowserView>
                      <Menu.Item>
                        <Button basic color="grey" onClick={this.onLogout}>
                          Log Out
                        </Button>
                      </Menu.Item>
                    </BrowserView>
                    <MobileView>
                      <Menu.Item>
                        <Button
                          basic
                          circular
                          icon="log out"
                          onClick={this.onLogout}
                        ></Button>
                      </Menu.Item>
                    </MobileView>
                  </Menu.Menu>
                </Menu>

              </Container>
            </Sticky>
            <HomeView
              admin={this.state.admin}
              app_user_id={this.state.app_user_id}
              logout={this.onLogout}
              priceChangeView={this.state.priceChangeView}
              invoiceView= {this.state.invoiceView}
              priceCheckView={this.state.priceCheckView}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="ui inverted segment"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="ui raised very padded text container segment"
            style={{ marginTop: "10px" }}
            align="center"
          >
            <div>
              <Image
                src="https://www.getgokart.com/gokart/wp-content/uploads/2017/10/shakened logo.png"
                size="small"
              />
              <h5>in association with</h5>
              <Image
     src="https://www.getgokart.com/gokart/wp-content/uploads/2017/10/Gokart Logo.jpg"
     size="tiny"
   />
              <h2 style={{ color: "#536571" }}>Hospitality's New Friend</h2>
            </div>
            <Grid relaxed style={{ marginTop: "20px" }}>
              <Grid.Column textAlign="center">
                <h3
                  style={{
                    display: "inline-block",
                    color: "#536571",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Phone :
                </h3>
                <InputBox
                  placeholderText={this.state.placeholderText}
                  onSubmit={this.onSubmit}
                  pinOrPhoneNumber={"phone"}
                  value={this.state.phone}
                  maxLength="11"
                  type={"text"}
                />
                <h3
                  style={{
                    display: "inline-block",
                    color: "#536571",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Pin :
                </h3>
                <InputBox
                  placeholderText={this.state.pinPlaceholderText}
                  onSubmit={this.onSubmit}
                  pinOrPhoneNumber={"pin"}
                  value={this.state.pin}
                  type={"password"}
                  maxLength={"4"}
                />
              </Grid.Column>
            </Grid>

            <button
              className="ui primary button"
              style={{ margin: "10px" }}
              onClick={this.login}
            >
              Login
            </button>
            <div>
              <label>{this.state.error}</label>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default LoginView;
