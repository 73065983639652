import React from "react";
import {Table, Icon} from "semantic-ui-react";

const PriceCheckList = (props) => {

const priceCheck = props.priceCheck.map((priceCheck) => {
  return (
      <tr key={priceCheck.product_code} style = {{borderWidth : '2px'}}>
      <td align="middle" style={{textAlign:"center"}}>
      {priceCheck.supplier}
      </td>
          <td align="middle" style={{textAlign:"center"}}>
          {priceCheck.description}
          </td>
          <td align="middle" style={{textAlign:"center"}}>
          £{priceCheck.price.toFixed(2)}
          </td>
          {(priceCheck.lowest-priceCheck.price) >0 ?
                    <td align="middle" style={{textAlign:"center", backgroundColor: "green", color: "white"}}>
                    Good Price
                    </td>
          : (priceCheck.lowest-priceCheck.price) == 0 ?
          <td align="middle" style={{textAlign:"center", backgroundColor: "orange", color: "white"}}>
          Fair Price
          </td>
          :

          <td align="middle" style={{textAlign:"center", backgroundColor: "red", color: "white"}}>
          Too Expensive
          </td>
        }
        <td align="middle" style = {{marginLeft :'10px'}} >
{priceCheck.saving >= 0 ? '' : priceCheck.position  == 1 ? <Icon name="pound" color='yellow' ></Icon> : priceCheck.position  == 2 ? <div><Icon name="pound" color='orange' ></Icon><Icon name="pound" color='orange' ></Icon></div> : priceCheck.position  == 3 ? <div><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='pink' ></Icon></div> : priceCheck.position  == 4 ? <div><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon></div> :<div><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon><Icon name="pound" color='red' ></Icon></div>

}
        </td>

      </tr>
    );
});

  return (


    <div className="ui container" width="auto">
    <h2 style={{ marginTop:
    '40px', fontWeight: "bold", textAlign: "center", width : '80%', marginLeft : 'auto', marginRight: 'auto'}}>Price Check Your Purchases</h2>
      <table className="ui celled  table">
      <tr style = {{borderWidth : '2px'}}>
  <td align="middle" style={{textAlign:"center", backgroundColor: "#f2f2f2"}}>
      Supplier
      </td>

  <td align="middle" style={{textAlign:"center", backgroundColor: "f2f2f2"}}>
      Item
      </td>

  <td align="middle" style={{textAlign:"center", backgroundColor: "f2f2f2"}}>
      Your Price
      </td>
      <td align="middle" style={{textAlign:"center", backgroundColor: "f2f2f2"}}>
          Our Advice
          </td>
          <td align="middle" style={{textAlign:"center", backgroundColor: "f2f2f2"}}>
               Potential
              </td>
      </tr>
        <tbody>{priceCheck}</tbody>
      </table>
    </div>
  );


}


          //
          // <td align="middle" style={{textAlign:"center"}}>
          // £{priceCheck.lowest.toFixed(2)}
          // </td>
          // <td align="middle" style={{textAlign:"center"}}>
          //     Gokart Members Price
          //     </td>


export default PriceCheckList;
