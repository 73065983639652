import React from "react";
import nochanges from './images/nochanges.png';
import {
  Dimmer,
  Loader,
  Container,
  Image,
  Icon
} from "semantic-ui-react";

import AdminView from "./AdminView";
import PriceCheck from "./PriceCheck";
import PriceChanges from "./PriceChanges";
import axios from "axios";

class HomeView extends React.Component {


  state = {
    pricechanges: [],
    impactOnCostsValue: 0,
    pricesloading: true,
    sidebarVisible: false,
  };

  componentDidMount() {
    console.log(nochanges);
    this.priceChangesCheck(this.props.app_user_id);
  }

  priceChangesCheck = async (app_user_id) => {
    const paramsid = { id: this.props.app_user_id };
    const response = await axios.post(
      `https://phpstack-612014-1983801.cloudwaysapps.com/price_changes`,
      paramsid,
      {
        headers: this.headers,
      }
    );
    if (response.data.message === "success") {
      this.setState({ pricechanges: response.data.results, error: "" });
      let totalImpact = response.data.results
        .reduce((a, b) => a + (b["impact"] || 0), 0)
        .toFixed(2);
      this.setState({ impactOnCostsValue: totalImpact, pricesloading: false });
    } else {
      this.setState({
        pricechanges: response.data.results,
        error: " No Price Changes",
      });
    }
  };

  logout = (e) => {
    if (e) {
      this.props.showMenu(true);
    }
  };





  render() {
    return (
      <div ref={this.contextRef}>
                  {this.props.priceChangeView === "true" ? (
                    this.state.pricesloading === true ? (
                      <Container textAlign = 'center'>
                        <Dimmer active inverted>
                          <Loader inverted>Checking for Latest Price Changes</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                      </Container>
                    ) : (
this.state.pricechanges.length === 0 ? [

  <h2 style={{ marginTop:
'40px', fontWeight: "bold", textAlign: "center", width : '80%', marginLeft : 'auto', marginRight: 'auto'}}> There are NO price changes that will affect you</h2>,
  <Container textAlign = 'center'><Icon name = 'clipboard check' color = 'blue' size = 'massive'/></Container>] :


                      <PriceChanges
                        pricechanges={this.state.pricechanges}
                        style={{ marginTop: "20px" }}
                        impactOnCostsValue={this.state.impactOnCostsValue}
                        app_user_id = {this.props.app_user_id}
                      />
                    )
                  ) : <>
                      {this.props.invoiceView === "true" ?
                      (
                        <AdminView
                          admin={this.props.admin}
                          app_user_id={this.props.app_user_id}
                        />
                      )
                      : <PriceCheck
                        app_user_id={this.props.app_user_id}
                        />
                    } </>

                }
      </div>
    );
  }
}
export default HomeView;
