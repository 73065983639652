import React from "react";
import { Card, Icon, Container, Grid, Label, Divider, Button } from "semantic-ui-react";
import "./Invoice.css";
import mixpanel from 'mixpanel-browser';


mixpanel.init('2d772417592a72a1c53239ba9ed8c7cd');

class PriceChanges extends React.Component {
  constructor (props) {
    super(props)
    this.state= {showChanges : false}
    this.handleClick = this.handleClick.bind(this)



  }


cards = this.props.pricechanges.map((data) => {
    return (
      <Grid.Column mobile={16} tablet={8} computer={4}>
        <Card>
          <Card.Content>
            <Container item>
              <Divider />
              <Grid columns={2}>
                <Grid.Column>
                  <Card.Header
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1em",
                      marginTop: "10px",
                      wordWrap: "normal",
                    }}
                  >
                    {" "}
                    {data.item}{" "}
                  </Card.Header>
                </Grid.Column>
                <Grid.Column>
                  {data.change < 0 ? (
                    <Label
                      style={{
                        fontWeight: "bold",
                        fontSize: ".8em",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "15px",
                        marginBottom: "4px",
                      }}
                      color="green"
                    >
                      <Icon
                        style={{
                          marginBottom: "5px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        name="chevron down"
                        color="white"
                      />{" "}
                      {data.change.toFixed(1)} %{" "}
                    </Label>
                  ) : (
                    <Label
                      style={{
                        fontWeight: "bold",
                        fontSize: ".8em",
                        marginTop: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "4px",
                      }}
                      color="red"
                    >
                      <Icon
                        style={{
                          marginBottom: "5px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        name="chevron up"
                        color="white"
                      />{" "}
                      {data.change.toFixed(1)} %{" "}
                    </Label>
                  )}
                </Grid.Column>
              </Grid>
            </Container>
            <Divider />
            {data.change < 0 ? (
              <Card.Description>
                {" "}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  New Price £ {data.newPrice.toFixed(2)}{" "}
                </div>
              </Card.Description>
            ) : (
              <Card.Description>
                {" "}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  New Price £ {data.newPrice.toFixed(2)}{" "}
                </div>
              </Card.Description>
            )}

            <span
              style={{ textAlign: "left", color: "grey", fontStyle: "italic" }}
            >
              {" "}
              Was £{data.oldPrice.toFixed(2)}{" "}
            </span>
            <div>
              {data.change < 0 ? (
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "5px",
                    marginBottom: "15px",
                    color: "green",
                  }}
                >
                  {" "}
                  Monthly costs decrease by -£{Math.abs(
                    data.impact.toFixed(2)
                  )}{" "}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "5px",
                    marginBottom: "15px",
                    color: "red",
                  }}
                >
                  {" "}
                  Monthly costs increase by £{Math.abs(
                    data.impact.toFixed(2)
                  )}{" "}
                </div>
              )}
            </div>
          </Card.Content>
          <Card.Content extra style={{ fontStyle: "italic" }}>
            {data.supplier}
          </Card.Content>
        </Card>
      </Grid.Column>
    );
  });


 handleClick = () => {
  this.setState ({showChanges: true})
  mixpanel.track('Show Prices',{
    distinct_id: this.props.app_user_id});
}

    render() {
  return (
    <div style={{ marginTop: "60px" }}>
      <Container fluid>
        <h1 style={{ textAlign: "center" }}>There {this.props.pricechanges.length === 1 ? 'is ' +this.props.pricechanges.length+ ' Price Change ' : 'are ' + this.props.pricechanges.length+ ' Price Changes '  } that will </h1>
          {this.props.impactOnCostsValue > 0 ? (
          <h1 style={{ fontWeight: "bold", textAlign: "center", color: "red" }}>
            Increase Costs By £{Math.abs(this.props.impactOnCostsValue).toFixed(2)}
          </h1>
        ) : (
          <h1
            style={{ fontWeight: "bold", textAlign: "center", color: "green" }}
          >
            Decrease Costs by - £{Math.abs(this.props.impactOnCostsValue).toFixed(2)}
          </h1>
        )
    }

{this.state.showChanges ?
<Grid container columns={3}>{this.cards}</Grid> :
  <Container textAlign = 'center' style = {{marginTop:'40px'}}><Button primary size = 'massive' onClick = {this.handleClick}> Tap to See Changes</Button></Container>
}

</Container>
    </div>
  );
}
};

export default PriceChanges;
