import React from "react";

import { Input } from 'semantic-ui-react';

class ImportBox extends React.Component {

  state = { placeholderText: null, entry :'' }

componentDidMount(){
    this.setState({placeholderText : this.props.placeholderText})
}

  onChangeSubmit=(entry)=>{
  this.props.onSubmit(entry.target.value,this.props.pinOrPhoneNumber)



  }
  render() {
    return (
        <div className="ui icon input">
          <Input
          width = '100%'
          placeholder={this.state.placeholderText}
          onChange = {this.onChangeSubmit}
          value = {this.props.value}
          type ={this.props.type}
          maxLength = {this.props.maxLength}
          >
          </Input>
      </div>
    );
  }
}

export default ImportBox;
