import React from "react";
import axios from "axios";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import {Container  } from "semantic-ui-react";
import InvoiceList from "./InvoiceList";
import InvoiceListMobile from "./InvoiceListMobile";
import SearchInput from "./SearchInput";
import download from "downloadjs";

class AdminView extends React.Component {
  state = { invoices: [], error: "", impactOnCostsValue :0 };

  componentDidMount() {
    if (!this.props.admin) {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      const dateMonth = date.getMonth() + 1;
      const curr_date =
        date.getFullYear() + "-" + dateMonth + "-" + date.getDate();
      this.onSearchSubmit(this.props.app_user_id, curr_date);
    }
    //this.priceChangesCheck(this.props.app_user_id);
  }



  onCsvDownload = async (entry, startDate, amount, endDate) => {
    const paramsid = {
      id: entry,
      amount: amount,
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .post(
        "https://phpstack-612014-1983801.cloudwaysapps.com/invoice_results",
        paramsid,
        {
          headers: this.headers,
        }
      )
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, "invoices.csv", "text/csv");
      })
      .catch((error) => console.log(error));
  };

  onSearchSubmit = async (entry, startDate, amount, endDate) => {
    this.setState({ invoices: [] });
    this.setState({ error: "" });
    const paramsid = {
      id: entry,
      start: startDate,
      end: endDate,
      amount: amount
    };
console.log(paramsid);

    const response = await axios.post(
      "https://phpstack-612014-1983801.cloudwaysapps.com/invoices_online_table",
      paramsid,
      {
        headers: this.headers,
      }
    )

    response.data.message === "success"
      ? this.setState({ invoices: response.data.results, error: "" })
      : this.setState({
          invoices: response.data.results,
          error: " No invoices available for these dates",
        });
        console.log(response.data.results);
  };

  logout = (e) => {
    if (e) {
      this.props.logout(true);
    }
  };
  render() {
    return (
      <Container>
        <SearchInput
          onCsvDownload={this.onCsvDownload}
          onSearchSubmit={this.onSearchSubmit}
          adminCust={this.props.admin}
          app_user_id={this.props.app_user_id}
          amount={this.amount}
          logout={this.logout}
        />
        <BrowserView>
        <InvoiceList invoices={this.state.invoices} />
        </BrowserView>
        <MobileView>
        <InvoiceListMobile invoices={this.state.invoices} />
        </MobileView>
        <h1 style={{ marginTop: "100px" }} align="center">
          {this.state.error}
        </h1>
      </Container>
    );
  }
}

export default AdminView;
