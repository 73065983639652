import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

class DateSelector extends Component {

  constructor (props) {
    super(props)
    let firstDate = new Date ();
    firstDate.setDate(firstDate.getDate() -7);
  //  const startdateMonth = firstDate.getMonth() + 1
    //const startCurr_Date = firstDate.getFullYear()+"-"+startdateMonth+"-"+firstDate.getDate();
    this.state = {
      startDatePicker: this.props.startDatePicker , startDate :this.props.startDate
    };
;
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

componentDidMount = () =>{
  this.props.fetchDate(this.state.startDate);
}

  handleChange = (date) => {
    const dateMonth = date.getMonth() + 1
    const curr_date = date.getFullYear()+"-"+dateMonth+"-"+date.getDate();

    this.setState({
      startDate: curr_date,
      startDatePicker: date
    },
() =>this.props.fetchDate(this.state.startDate));
  }

  handleSelect(date) {


    this.setState({
      startDate: date,
      startDatePicker: date
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
  }


  render() {
    return (
          <DatePicker
              selected={ this.state.startDatePicker }
              onChange={this.handleChange}
              name="startDate"
             dateFormat="dd MMMM yyyy"
          />
    );
  }

}

export default DateSelector;
