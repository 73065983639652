import React from "react";
import LoginView from './LoginView';

// 07408835847 8658 - login prairie fire

class App extends React.Component {

  componentDidMount = () => {
     caches.keys().then((names) => {
       names.forEach((name) => {
         caches.delete(name);
       });
     });
   };


  render() {
    return (
      <LoginView />
    );
  }
}

export default App;
