import React from "react";
import { DataGrid, useGridSlotComponentProps } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from '@material-ui/styles';
import'./Invoice.css';

const InvoiceList = (props) => {

  const [pageSize, setPageSize] = React.useState(10);

  function getCredit(params) {
    return params.getValue(params.id, 'credit_note_number') ===  null ? '': `Credit Note: ${params.getValue(params.id, 'credit_note_number') || ''} for -£ ${
      params.getValue(params.id, 'credit_amount') || ''
    } applied`;
  }

function getInvoice(params) {
  return `${params.getValue(params.id, 'official_company_name') || ''} - Invoice:  ${
     params.getValue(params.id, 'invoice_num') || ''
   }`;
 }


  const rows = props.invoices;

  const columns = [
    {
      field: 'id',
      headerName: 'order ref',
      align: 'center',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      headerAlign: 'center',
      hide: true,
      flex: .2,
    },
    {
      field: 'payment_date',
      headerName: 'Paid Date',
      type: 'date',
      align: 'center',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      headerAlign: 'center',
      flex: 1.2,
      valueFormatter: (params) =>{
        const formattedDate = params.value === " " ? ' ': new Date(params.value).toLocaleDateString("en-GB");
        return `${formattedDate}`;
      }
    },

    {
      field: 'credit_applied',
      headerName: 'Credit Note Applied',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      headerAlign: 'center',
      flex: 2,
    valueGetter: getCredit
    },
    { field: 'supplier_invoice',
      headerName: 'Supplier',
      headerAlign: 'center',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      align: 'left',
      flex: 1.8,
      valueGetter:  getInvoice,
    },
    {
      field: 'order_total',
      headerName: 'Total',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      type: 'number',
      headerAlign: 'center',
      flex: 0.9,
      valueFormatter: (params) =>{
        const formattedCreditAmount = params.value;
        return formattedCreditAmount === " " ? ``: `£ ${formattedCreditAmount}`;
      }
    },
    {
      field: 'vat_total',
      headerName: 'Vat',
      headerClassName:  'headerInvoice',
      cellClassName: 'labelInvoice',
      type: 'number',
      headerAlign: 'center',
      flex: .8,
      valueFormatter: (params) =>{
        const formattedCreditAmount = params.value;
        return formattedCreditAmount === "0" || formattedCreditAmount === " "? ``: `£ ${formattedCreditAmount}`;
      }
    },

    { field: 'bank_charge',
      headerName: 'Payment',
      headerClassName:  'headerInvoice',
      type: 'number',
      headerAlign: 'center',
      //headerClassName:  'headerInvoice',
      align: 'center',
      cellClassName: 'labelInvoice3',
      flex: 1.2,
      valueFormatter: (params) =>{
        const formattedDate = params.value;
        return `£ ${formattedDate}`;
      }
    },
    {
      field: 'link',
     headerName: 'Invoice',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      headerClassName:  'headerInvoice',
     cellClassName: 'labelInvoice',

     renderCell: (params: GridCellParams) =>(
       params.value === "Balance Charge" ? <label ></label> : params.value === "" ? <label >Awaiting Document</label> : <a href={params.value}>
         <button
           className = 'ui icon primary button'
           width="100%"
           position="absolute"
           style ={{justifyContent:'center', alignItems: 'center', display:'flex'}}
         >
         <i aria-hidden="true" class="cloud download icon"></i>
         </button>
     </a>
     )
   },
  ];

  const handlePageSizeChange = (params) => {

   setPageSize(params.pageSize);
 };

 const useStyles = makeStyles({
   root: {
     '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: '#536571',
      wordWrap: 'normal',
       fontWeight:"60",
       color:'#fff',
       whiteSpace: 'nowrap',
     },
     '& .MuiDataGrid-columnHeaderTitleContainer': {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        flex: 1,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        padding: '0 6px',
      },
   },
 });

  const classes = useStyles();

  function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();

    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={state.pagination.page}
        count={state.pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value)}
      />
    );
  }

  return (
    <div style={{  display: 'flex', height: 530, width : '100%',paddingLeft: '10px', paddingRight: '10px' }} className={classes.root}>
         <DataGrid
         className={classes.root}
         rows={rows}
         columns={columns}
         autoHeight
         pageSize={pageSize}
         paginationMode= 'client'
        onPageSizeChange={handlePageSizeChange}
         rowsPerPageOptions={[5, 10, 20]}
scrollbarSize = '15'
        />
       </div>
     );
};

export default InvoiceList;
