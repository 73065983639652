import React from "react";
import'./Invoice.css';

const InvoiceListMobile = (props) => {

  const invoices = props.invoices.map((invoice) => {
    return (
      <tr key={invoice.id} style = {{borderWidth : '2px'}}>
        <td align="middle" style={{
                        textAlign:"center"
                      }}>{new Date(invoice.payment_date).toLocaleDateString("en-GB")}</td>
                      <td align="middle" style={{
                                      textAlign:"center"
                                    }} >Paid Amount: £{Number(invoice.bank_charge).toFixed(2)}</td>
                      <td>
        <td align="middle" style={{
                        textAlign:"center"
                      }}>{invoice.official_company_name+' - Invoice #'+ invoice.invoice_num}</td>
        <td align="middle" style={{
                        textAlign:"center"
                      }} >£{Number(invoice.order_total).toFixed(2)} (inc vat: £{Number(invoice.vat_total).toFixed(2)}){
invoice.credit_note_number === null ? '' : ' Credit Note: '+ invoice.credit_note_number +' for -£' + invoice.credit_amount.toFixed(2) + ' applied'

                      }</td>

          {invoice.link === "" ? (
            <div className="labelInvoice" align="middle" width="100%" style ={{justifyContent:'center', alignItems: 'center', display:'flex',  backgroundColor: 'grey'}}><label style = {{fontStyle : 'italic', color: 'white'}}>No document available</label></div>
          ) : (
            <div className="ui primary buttons" style ={{justifyContent:'center', alignItems: 'center', display:'flex'}}>
                <a href={invoice.link}>
                  <button
                  //className = 'buttonInvoice'
className = 'ui primary button'
                    width="100%"
                    position="absolute"
                    style ={{justifyContent:'center', alignItems: 'center', display:'flex'}}

                  >
                  Download
                  </button>
              </a>
            </div>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="ui container" width="auto">
      <table className="ui celled  table">
        <tbody>{invoices}</tbody>
      </table>
    </div>
  );
};

export default InvoiceListMobile;
