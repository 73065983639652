import React, {useState} from 'react';
import {
  Container,
  Image,
  Icon,
  Button, Header, Segment
} from "semantic-ui-react";
import axios from "axios";
import { PickerDropPane } from 'filestack-react';


function Upload () {
const state = {see: "false"}
function onPick (){

}

   return (
<div style ={{marginTop: "20px", marginBottom: "20px"}}>
        <PickerDropPane
          apikey='Ar7QClU8QTumnlazGXFLhz'
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => console.log(res)}
        />
</div>
)


}

export default Upload;
